<template>
  <q-item
    class="text-white"
    clickable
    @click="openWindow(footerItem.windowPage)"
  >
    <q-item-section>{{ footerItem.label }}</q-item-section>
  </q-item>
</template>

<script setup>
defineProps({
  footerItem: {
    type: Object,
    required: true,
  },
})

function openWindow(windowPage) {
  window.open(windowPage, '_blank')
}
</script>
