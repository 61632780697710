<template>
  <q-list>
    <template v-for="footerItem in footerItems" :key="footerItem.id">
      <custom-drawer-footer-item :footer-item="footerItem" />
      <q-separator />
    </template>
    <q-item class="row items-center text-white">
      <q-icon
        v-for="socialMedia in socialMedias"
        :key="socialMedia.id"
        :name="socialMedia.icon"
        class="q-pa-sm"
        size="18px"
        clickable
        @click="openWindow(socialMedia.windowPage)"
      />
    </q-item>
  </q-list>
</template>

<script setup>
import CustomDrawerFooterItem from './CustomDrawerFooterItem.vue'

const footerItems = [
  {
    id: 1,
    label: 'Termos de Uso',
    windowPage: 'https://plt-termos-de-servicos.s3.amazonaws.com/terms.pdf',
  },
  {
    id: 2,
    label: 'Política de Privacidade',
    windowPage:
      'https://plt-termos-de-servicos.s3.amazonaws.com/privacy-terms.pdf',
  },
  {
    id: 3,
    label: 'CRDC Todos os direitos reservados',
    windowPage: 'https://crdc.com.br/',
  },
]

const socialMedias = [
  {
    id: 1,
    icon: 'fa-brands fa-facebook',
    windowPage: 'https://www.facebook.com/crdccentralderegistros/',
  },
  {
    id: 2,
    icon: 'fa-brands fa-instagram',
    windowPage: 'https://www.instagram.com/centralderegistros_crdc/',
  },
  {
    id: 3,
    icon: 'fa-brands fa-whatsapp',
    windowPage: 'https://contate.me/crdc',
  },
]

function openWindow(windowPage) {
  window.open(windowPage, '_blank')
}
</script>
